<template>
  <div class="main-box">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="ec-box">
        <div class="content">
          <div class="con-item">
            <a-form-item label="信息类别:" name="xinxileibie">
              <a-select
                v-model:value="xinxileibie"
                @change="onPickType"
                placeholder="请选择信息类别"
              >
                <a-select-option value="news">咨询动态</a-select-option>
                <a-select-option value="policy">政策指引</a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="关键词:" name="guanjianci">
              <a-select v-model:value="guanjianci" placeholder="请选择关键词">
                <a-select-option value="融资担保">融资担保</a-select-option>
                <a-select-option value="减税降费">减税降费</a-select-option>
                <a-select-option value="中小企业">中小企业</a-select-option>
                <a-select-option value="产业扶持">产业扶持</a-select-option>
                <a-select-option value="政府补贴">政府补贴</a-select-option>
                <a-select-option value="创新创业">创新创业</a-select-option>
                <a-select-option value="金融扶持">金融扶持</a-select-option>
                <a-select-option value="数字经济">数字经济</a-select-option>
                <a-select-option value="疫情防控">疫情防控</a-select-option>
                <a-select-option value="贴息政策">贴息政策</a-select-option>
                <a-select-option value="招商引资">招商引资</a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="con-item" v-if="pShow">
            <a-form-item label="政策等级:" name="zhengcedengji">
              <a-select
                v-model:value="zhengcedengji"
                placeholder="请选择政策等级"
              >
                <a-select-option value="国">国</a-select-option>
                <a-select-option value="省">省</a-select-option>
                <a-select-option value="市">市</a-select-option>
                <a-select-option value="区">区</a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="发布时间:" name="fabushijian">
              <a-date-picker
                v-model:value="fabushijian"
                :format="dateFormat"
                @change="onPickRegDate"
                style="width: 100%"
                placeholder="请选择发布时间"
              />
            </a-form-item>
          </div>
          <div class="con-item-upload">
            <a-form-item label="导图:" name="fujianwenjian">
              <a-upload
                class="upload-demo"
                :action="$uploadApi"
                :before-upload="beforeupload"
                :default-file-list="filelist"
                name="file"
                :limit="1"
                :multiple="true"
              >
                <a-button>上传附件</a-button>
              </a-upload>
              <span style="margin-left:50px">{{ importFileName }}</span>
            </a-form-item>
          </div>

          <!--<div class="fileimg-ct">-->
          <!--	<input type="file" name="file" id="fileImg" accept=".gif,.jpg,.jpeg,.png" @change="getImgURL(this)" >-->
          <!--</div>-->

          <div class="con-item">
            <a-form-item label="信息标题:" name="xinxibiaoti">
              <a-input
                v-model:value="xinxibiaoti"
                placeholder="请输入信息标题"
              />
            </a-form-item>
          </div>
          <a-form
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
            style="width: 100%;"
          >
            <a-form-item label="正文简介:" name="zhengwenjianjie">
              <a-textarea
                v-model:value="zhengwenjianjie"
                style="height: 100px;"
                placeholder="请输入信息正文"
              />
            </a-form-item>
          </a-form>
          <a-form
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
            style="width: 100%;"
          >
            <a-form-item label="信息正文:" name="xinxizhengwen">
              <!--                            <a-textarea v-model:value="xinxizhengwen" style="height: 300px;" placeholder="请输入信息正文" />-->
              <editor
                v-model:value="xinxizhengwen"
                @input="gettinymceinput"
              ></editor>
            </a-form-item>
          </a-form>
          <div class="contents">
            <a-button @click="onSubmit">确认提交</a-button>
          </div>
        </div>
      </div>
    </a-form>
    <!-- 确认弹窗 -->
    <a-modal
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      title="消息提示"
      :maskClosable="false"
      @ok="handleOk"
    >
      <p>请确认信息是否正确无误!!!</p>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/zh-cn";
import axios from "@/api/axios";
import Editor from "../../components/imcoder-tinymce";
import { UploadOutlined } from "@ant-design/icons-vue";

export default {
  computed: {
    ...mapGetters(["userName", "orgName", "orgId"]),
    UploadOutlined,
  },
  components: { Editor },
  data() {
    return {
      filelist: [],
      visible: false,
      confirmLoading: false,
      importFileName: null,
      param: new FormData(),
      headers: {
        authorization: "authorization-text",
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
      labelCol1: {
        span: 3,
      },
      wrapperCol1: {
        span: 20,
      },
      moment,
      guanjianci: "",
      xinxileibie: "",
      zhengcedengji: "",
      fabushijian: "",
      dateFormat: "YYYY-MM-DD",
      xinxizhengwen: "",
      zhengwenjianjie: "",
      pShow: true,
      xinxibiaoti: "",
      fileAddr: "",
      id: "",
    };
  },

  methods: {
    onPickRegDate(date, dateString) {
      this.fabushijian = dateString;
    },
    gettinymceinput(val) {
      this.xinxizhengwen = val;
    },
    beforeupload(file) {
      this.filelist = file;
      this.importFileName = file.name;

      // this.param.set("FFILE", file,file.name);
    },
    //覆盖默认的上传行为
    httprequest() {},

    getImgURL(node) {
      let file = null;
      alert(23);

      let fhFile = document.getElementById("fileImg").files[0];
      alert(fhFile.name);
    },
    onPickType(e) {
      if (e === "policy") {
        this.pShow = true;
      } else {
        this.pShow = false;
      }
    },
    onSubmit() {
      this.getIsValid();
      //   let isValid = this.getIsValid();
      if (this.filelist.length == 0) {
        // this.$message({
        //     message: "请上传文件！",
        //     type: 'warning'
        // });
        alert("请上传文件");
        return false;
      }
      // alert("FFILE:"+fhFile.size)
      // let fhFile1 =document.getElementById("fileImg").files[0]
      // alert("fileImg:"+fhFile1.size)
      //   if (isValid) {
      //     // /informaton/add
      //     var params = new FormData();
      //     params.append("INFOR_TYPE", this.xinxileibie);
      //     params.append("INFOR_KEY", this.guanjianci);
      //     params.append("POLICY_LEVEL", this.zhengcedengji);
      //     params.append("PROM_DATE", this.fabushijian);
      //     params.append("FILE_ADDR", this.fileAddr);
      //     params.append("INFOR_TITLE", this.xinxibiaoti);
      //     params.append("INFOR", this.xinxizhengwen);
      //     params.append("INFOR_INTRO", this.zhengwenjianjie);
      //     params.append("FFILE", this.filelist);
      //     params.append("USER_ID", localStorage.getItem("userId"));
      //     params.append("XCX_NAME", localStorage.getItem("xcxName"));
      //     // params.append('FILEIMG',fhFile1)
      //     params.append("tm", new Date().getTime());
      //     axios.post(this.$baseUrl + "/informaton/add", params).then((res) => {
      //       if (res.result === "success") {
      //         this.$message.success("信息发布成功！");
      //         this.init();
      //         this.confirmLoading = false;
      //       } else {
      //         this.$message.error("系统异常，请稍后重试");
      //       }
      //     });
      //   }
      this.visible = true;
    },
    handleOk() {
      this.confirmLoading = true;
      if (this.$route.query.type === "edit") {
        this.onEdit();
      } else {
        this.onAdd();
      }
    },
    // 新增
    onAdd() {
      var params = new FormData();
      params.append("INFOR_TYPE", this.xinxileibie);
      params.append("INFOR_KEY", this.guanjianci);
      params.append("POLICY_LEVEL", this.zhengcedengji);
      params.append("PROM_DATE", this.fabushijian);
      params.append("FILE_ADDR", this.fileAddr);
      params.append("INFOR_TITLE", this.xinxibiaoti);
      params.append("INFOR", this.xinxizhengwen);
      params.append("INFOR_INTRO", this.zhengwenjianjie);
      params.append("FFILE", this.filelist);
      params.append("USER_ID", localStorage.getItem("userId"));
      params.append("XCX_NAME", localStorage.getItem("xcxName"));
      // params.append('FILEIMG',fhFile1)
      params.append("tm", new Date().getTime());
      axios.post(this.$baseUrl + "/informaton/add", params).then((res) => {
        if (res.result === "success") {
          this.$message.success("信息发布成功！");
          this.init();
          this.visible = false;
          this.confirmLoading = false;
        } else {
          this.$message.error("系统异常，请稍后重试");
          this.confirmLoading = false;
        }
      });
      //   let isValid = this.getIsValid();
      //   if (this.filelist.length == 0) {
      //     // this.$message({
      //     //     message: "请上传文件！",
      //     //     type: 'warning'
      //     // });
      //     alert("请上传文件");
      //     return false;
      //   }
      // alert("FFILE:"+fhFile.size)
      // let fhFile1 =document.getElementById("fileImg").files[0]
      // alert("fileImg:"+fhFile1.size)
      //   if (isValid) {
      //     // /informaton/add
      //     var params = new FormData();
      //     params.append("INFOR_TYPE", this.xinxileibie);
      //     params.append("INFOR_KEY", this.guanjianci);
      //     params.append("POLICY_LEVEL", this.zhengcedengji);
      //     params.append("PROM_DATE", this.fabushijian);
      //     params.append("FILE_ADDR", this.fileAddr);
      //     params.append("INFOR_TITLE", this.xinxibiaoti);
      //     params.append("INFOR", this.xinxizhengwen);
      //     params.append("INFOR_INTRO", this.zhengwenjianjie);
      //     params.append("FFILE", this.filelist);
      //     params.append("USER_ID", localStorage.getItem("userId"));
      //     params.append("XCX_NAME", localStorage.getItem("xcxName"));
      //     // params.append('FILEIMG',fhFile1)
      //     params.append("tm", new Date().getTime());
      //     axios.post(this.$baseUrl + "/informaton/add", params).then((res) => {
      //       if (res.result === "success") {
      //         this.$message.success("信息发布成功！");
      //         this.init();
      //         this.confirmLoading = false;
      //       } else {
      //         this.$message.error("系统异常，请稍后重试");
      //       }
      //     });
      //   }
    },
    // 编辑
    onEdit() {
      var params = new FormData();
      params.append("INFORMATON_ID", this.id);
      params.append("INFOR_TYPE", this.xinxileibie);
      params.append("INFOR_KEY", this.guanjianci);
      params.append("POLICY_LEVEL", this.zhengcedengji);
      params.append("PROM_DATE", this.fabushijian);
      params.append("FILE_ADDR", this.fileAddr);
      params.append("INFOR_TITLE", this.xinxibiaoti);
      params.append("INFOR", this.xinxizhengwen);
      params.append("INFOR_INTRO", this.zhengwenjianjie);
      params.append("FFILE", this.filelist);
      params.append("tm", new Date().getTime());
      axios.post(this.$baseUrl + "/informaton/edit", params).then((res) => {
        if (res.result === "success") {
          this.$message.success("信息修改成功！");
          this.visible = false;
          this.confirmLoading = false;
          setTimeout(() => {
            this.$router.back();
          }, 1500);
        } else {
          this.$message.error("系统异常，请稍后重试");
          this.confirmLoading = false;
        }
      });
      //   let isValid = this.getIsValid();
      //   if (isValid) {
      //     var params = new FormData();
      //     params.append("INFORMATON_ID", this.id);
      //     params.append("INFOR_TYPE", this.xinxileibie);
      //     params.append("INFOR_KEY", this.guanjianci);
      //     params.append("POLICY_LEVEL", this.zhengcedengji);
      //     params.append("PROM_DATE", this.fabushijian);
      //     params.append("FILE_ADDR", this.fileAddr);
      //     params.append("INFOR_TITLE", this.xinxibiaoti);
      //     params.append("INFOR", this.xinxizhengwen);
      //     params.append("INFOR_INTRO", this.zhengwenjianjie);
      //     params.append("FFILE", this.filelist);
      //     params.append("tm", new Date().getTime());
      //     axios.post(this.$baseUrl + "/informaton/edit", params).then((res) => {
      //       if (res.result === "success") {
      //         this.$message.success("信息修改成功！");
      //         this.confirmLoading = false;
      //         setTimeout(() => {
      //           this.$router.back();
      //         }, 1500);
      //       } else {
      //         this.$message.error("系统异常，请稍后重试");
      //       }
      //     });
      //   }
    },
    getIsValid() {
      if (this.xinxileibie == undefined) {
        this.$message.info("请选择信息类别");
        return false;
      } else if (this.pShow && this.zhengcedengji == undefined) {
        this.$message.info("请选择政策等级");
        return false;
      } else if (this.guanjianci == "") {
        this.$message.info("请填写关键词");
        return false;
      } else if (this.fabushijian == "") {
        this.$message.info("请选择发布时间");
        return false;
      } else if (this.xinxibiaoti == "") {
        this.$message.info("请输入信息标题");
        return false;
      } else if (this.xinxizhengwen == "") {
        this.$message.info("请输入信息正文");
        return false;
      } else if (this.zhengwenjianjie == "") {
        this.$message.info("请输入正文简介");
        return false;
      } else {
        return true;
      }
    },
    // 初始化数据
    init() {
      this.xinxileibie = undefined;
      this.zhengcedengji = undefined;
      this.guanjianci = "";
      this.fabushijian = "";
      this.xinxibiaoti = "";
      this.xinxizhengwen = "";
      this.zhengwenjianjie = "";
      this.pShow = true;
      this.fileListName = "";
      this.fileAddr = "";
    },
    // 获取信息详情
    getInformationDetail() {
      this.$store
        .dispatch("getInformationDetail", {
          INFORMATON_ID: this.id,
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            let datas = res.pd;
            this.xinxileibie = datas.INFOR_TYPE;
            this.guanjianci = datas.INFOR_KEY;
            if (datas.INFOR_TYPE === "policy") {
              this.zhengcedengji = datas.POLICY_LEVEL;
            } else {
              this.pShow = false;
            }
            this.fileAddr = datas.FILE_ADDR;
            this.fabushijian = datas.PROM_DATE;
            this.xinxibiaoti = datas.INFOR_TITLE;
            this.xinxizhengwen = datas.INFOR;
            this.zhengwenjianjie = datas.INFOR_INTRO;
          } else {
            this.$message.error("系统异常，获取信息详情失败，请稍后重试");
          }
        });
    },
  },
  created() {
    if (this.$route.query.type === "edit") {
      this.id = this.$route.query.id;
      // 获取信息详情
      this.getInformationDetail();
    }
  },
};
</script>

<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead) {
  background: -webkit-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Firefox 3.6-15 */
  background: linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th) {
  background-color: transparent;
  color: @color-ff;
}
.content-box {
  text-align: center;
  ::v-deep(.ant-form-item-control-wrapper) {
    margin: 0 auto;
    text-align: center;
  }
  .ant-btn {
    padding: 0 15px;
    height: 40px;
    color: @color-ff;
    margin: 0 5px;
    background: -webkit-linear-gradient(
      to left,
      @color-blue,
      @color-purple
    ); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(
      to left,
      @color-blue,
      @color-purple
    ); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(
      to left,
      @color-blue,
      @color-purple
    ); /* Firefox 3.6-15 */
    background: linear-gradient(
      to left,
      @color-blue,
      @color-purple
    ); /* 标准语法 */
  }
}
</style>
<style lang="less" scoped>
.main-box {
  width: 100%;
  margin: 20px auto;
  .ec-box {
    padding: 15px;
    background-color: @color-ff;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;
    .title {
      font-size: @font-lg;
      padding: 0px 10px;
      padding-bottom: 15px;
      border-bottom: @border-base;
      display: flex;
      .tab {
        padding: 2px 15px;
        color: #a7a7a7;
        cursor: pointer;
      }
      .tab-active {
        padding: 2px 15px;
        color: #ffffff;
        cursor: pointer;
        background: -webkit-linear-gradient(
          to left,
          @color-blue,
          @color-purple
        ); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(
          to left,
          @color-blue,
          @color-purple
        ); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(
          to left,
          @color-blue,
          @color-purple
        ); /* Firefox 3.6-15 */
        background: linear-gradient(
          to left,
          @color-blue,
          @color-purple
        ); /* 标准语法 */
      }
    }
    .content {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .con-item {
        width: 49%;
      }
      .con-item-download {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        a {
          font-size: @font-lg;
          display: inline-block;
          text-decoration: underline;
          color: @color-ff;
          padding: 10px 60px;
          background: -webkit-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Safari 5.1-6.0 */
          background: -o-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Opera 11.1-12.0 */
          background: -moz-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Firefox 3.6-15 */
          background: linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* 标准语法 */
        }
      }
      .con-item-vercode {
        .ant-form-item {
          .ant-input {
            width: 55%;
          }
          .ant-btn {
            width: 40%;
            margin-left: 5%;
          }
        }
      }
      .con-item-upload {
        width: 49%;
        ::v-deep(.ant-upload) {
          width: 100%;
        }
        ::v-deep(.ant-btn) {
          width: 100%;
        }
      }
      .con-item-uploadimg {
        width: 30%;
        ::v-deep(.ant-upload) {
          width: 240px;
          height: 195px;
        }
        ::v-deep(.ant-upload-list-item) {
          width: 240px;
          height: 195px;
        }
        ::v-deep(.ant-upload-list-picture-card-container) {
          height: 195px;
        }
      }
      .contents {
        margin: 0 auto;
        ::v-deep(.ant-form-item-control-wrapper) {
          margin: 0 auto;
          text-align: center;
        }
        .ant-btn {
          padding: 0 120px;
          height: 40px;
          color: @color-ff;
          background: -webkit-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Safari 5.1-6.0 */
          background: -o-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Opera 11.1-12.0 */
          background: -moz-linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* Firefox 3.6-15 */
          background: linear-gradient(
            to left,
            @color-blue,
            @color-purple
          ); /* 标准语法 */
        }
      }
    }
  }
}
</style>
